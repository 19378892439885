import React from "react";
import { Link } from "gatsby";
import { ContactBar } from "../ContactBar";
import {
  Footer as StyledFooter,
  Wrapper,
  Separator,
  Links,
  List,
  Item,
  Contact,
  Heading,
  Details,
  Address,
  Label,
} from "./styles";

interface FooterType {}

export const Footer: React.FC<FooterType> = () => (
  <>
    <ContactBar />
    <StyledFooter>
      <Wrapper>
        <Links>
          <List>
            <Item>
              <Link to="/our-cars">Used cars</Link>
            </Item>
            <Item>
              <Link to="/our-cars">Used trucks &amp; commercials</Link>
            </Item>
            <Item>
              <Link to="/our-services">Workshop services</Link>
            </Item>
            <Item>
              <Link to="/#about-us">About us</Link>
            </Item>
            <Item>
              <Link to="/#contact-us">Contact us</Link>
            </Item>
          </List>
          <List>
            <Item>
              <Link to="/privacy-policy.pdf">Privacy policy</Link>
            </Item>
            <Item>
              <Link to="/gdpr.pdf">GDPR</Link>
            </Item>
          </List>
        </Links>
        <Separator />
        <Contact>
          <Heading>Get in touch</Heading>
          {/* <Details>01686 624162 | pughmotors@hotmail.co.uk </Details> */}
          <Address>
            <Label>Nigel Pugh Motors</Label>
            <br /> Unit 8A Lion Works Industrial Estate
            <br /> Pool Road
            <br /> Newtown
            <br /> Powys
            <br /> SY16 3AG
          </Address>
        </Contact>
      </Wrapper>
    </StyledFooter>
  </>
);
