import styled from "styled-components";
import { red, white } from "../../styles/colors";

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: ${red};
  color: ${white};
  padding: 10px 0;

  @media (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Item = styled.span`
  margin: 0 20px;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 0;
  justify-self: center;

  &:first-of-type {
    @media (min-width: 725px) {
      justify-self: flex-end;
    }
  }

  &:nth-of-type(2) {
    @media (min-width: 725px) {
      justify-self: flex-start;
    }
  }
`;
