import styled from "styled-components";
import { black, lightGrey, darkGrey } from "../../styles/colors";

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  background: ${black};
  color: ${lightGrey};
  padding: 0 20px;

  @media (min-width: 725px) {
    display: block;
  }

  a,
  a:visited,
  a:active,
  a:hover {
    color: ${lightGrey};
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 940px;
  margin: 36px auto;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 725px) {
    grid-template-columns: 1fr 10px 1fr;
  }
`;

export const Links = styled.div`
  order: 3;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;

  @media (min-width: 725px) {
    order: 1;
    margin-top: 35px;
    margin-right: 20px;
  }
`;

export const List = styled.ul`
  font-size: 16px;
  line-height: 2;
`;

export const Item = styled.li`
  font-weight: 300;
`;

export const Separator = styled.div`
  order: 2;
  background: ${darkGrey};
  height: 1px;
  margin: 30px 0;

  @media (min-width: 725px) {
    width: 1px;
    margin: 0 auto;

    height: 100%;
  }
`;

export const Contact = styled.div`
  order: 1;

  @media (min-width: 725px) {
    order: 3;
    text-align: right;
  }
`;

export const Heading = styled.h1`
  font-family: "Roboto Condensed";
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export const Details = styled.p`
  font-size: 16px;
  line-height: 2;
  font-weight: 300;
  margin-bottom: 5px;
`;

export const Label = styled.span`
  font-weight: 400;
`;

export const Address = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
`;
