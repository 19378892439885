import styled, { css } from "styled-components";
import { white, lightGrey } from "../../styles/colors";

const iconClose = css`
  background: transparent;
  &::before,
  &::after {
    position: absolute;
    content: " ";
    top: -5px;
    left: 13px;
    right: 0;
    height: 40px;
    width: 4px;
    background-color: #4c4d50;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const iconNav = css`
  background-image: linear-gradient(
    180deg,
    #4c4d50,
    #4c4d50 15%,
    #fff 0,
    #fff 42.5%,
    #4c4d50 0,
    #4c4d50 57.5%,
    #fff 0,
    #fff 85%,
    #4c4d50 0,
    #4c4d50
  );
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

export const Nav = styled.nav`
  ${(props) =>
    props.show &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      z-index: 1;
    `}

  @media (min-width: 850px) {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Toggle = styled.button`
  position: absolute;
  top: 17px;
  right: 15px;
  width: 30px;
  height: 30px;
  padding: 0;
  border: 0;
  cursor: pointer;

  ${(props) => (props.show ? iconClose : iconNav)}

  @media (min-width: 850px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  margin-top: 60px;
  height: 100%;
  background: ${white};

  @media (min-width: 850px) {
    display: block;
    margin-top: 10px;
    background: initial;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;

  @media (min-width: 850px) {
    flex-direction: row;
  }
`;

export const Item = styled.li`
  padding: 20px 0;
  font-size: 20px;
  display: block;
  text-align: center;
  border-bottom: 1px solid ${lightGrey};

  &:first-of-type {
    border-top: 1px solid ${lightGrey};

    @media (min-width: 850px) {
      border-top: 0;
    }
  }

  @media (min-width: 850px) {
    margin-left: 20px;
    padding: 0;
    border: 0;
    font-size: initial;
    text-align: left;
    display: inline;
  }
`;
