import React from "react"
import { SectionHeading as StyledSectionHeading, Background } from "./styles"

interface SectionHeadingType {
  hasBackground?: boolean
}

export const SectionHeading: React.FC<SectionHeadingType> = ({
  children,
  hasBackground,
}) => (
  <StyledSectionHeading hasBackground>
    {hasBackground ? (
      <Background>
        <span>{children}</span>
      </Background>
    ) : (
      children
    )}
  </StyledSectionHeading>
)
