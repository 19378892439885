import React from "react";
import { Link } from "gatsby";
import { Nav } from "../Nav";
import { Header as StyledHeader, LogoSmall, LogoLarge } from "./styles";

export const Header: React.FC = () => (
  <StyledHeader>
    <Link to="/">
      <LogoSmall scale={0.7} />
      <LogoLarge scale={1} />
    </Link>
    <Nav />
  </StyledHeader>
);
