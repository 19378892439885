import React, { useState } from "react";
import { Link } from "gatsby";
import { Nav as StyledNav, Toggle, Wrapper, List, Item } from "./styles";

export const Nav: React.FC = () => {
  const [showNav, setShowNav] = useState(false);

  return (
    <StyledNav show={showNav}>
      <Toggle show={showNav} onClick={() => setShowNav(!showNav)} />
      <Wrapper show={showNav}>
        <List>
          <Item>
            <Link to="/our-cars">Used cars</Link>
          </Item>
          <Item>
            <Link to="/our-cars">Used trucks &amp; commercials</Link>
          </Item>
          <Item>
            <Link to="/our-services">Workshop services</Link>
          </Item>
          <Item>
            <Link to="/#about-us">About us</Link>
          </Item>
          <Item>
            <Link to="/#contact-us">Contact us</Link>
          </Item>
        </List>
      </Wrapper>
    </StyledNav>
  );
};
