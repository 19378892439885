import styled from "styled-components";
import { white } from "../../styles/colors";

export const SectionHeading = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: 850px) {
    text-align: inherit;
  }
`;

export const Background = styled.div`
  color: ${white};
  font-size: 28px;
`;
