import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Wrapper, Content } from "./styles";

interface LayoutProps {}

export const Layout: React.FC<LayoutProps> = ({ children }) => (
  <Wrapper>
    <Header />
    <Content>{children}</Content>
    <Footer />
  </Wrapper>
);
