import styled from "styled-components";
import { Logo as _Logo } from "../Logo";

export const Header = styled.header`
  z-index: 3;
  display: flex;
  align-items: center;
  height: 65px;
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  @media (min-width: 850px) {
    height: 90px;
  }
`;

export const LogoSmall = styled(_Logo)`
  margin-top: 13px;

  @media (min-width: 850px) {
    display: none;
  }
`;

export const LogoLarge = styled(_Logo)`
  display: none;

  @media (min-width: 850px) {
    display: block;
  }
`;
